import * as Analytics from '@rushplay/analytics'
import * as Api from '@rushplay/api-client'

import * as Player from './redux-player'

const OPEN_PROMOTION_MENU = 'analytics/OPEN_PROMOTION_MENU'
const CLAIM_PROMOTION = 'analytics/CLAIM_PROMOTION'
const BROWSE_PROMOTION = 'analytics/BROWSE_PROMOTION'
const ODDS_WIDGET_CLICKED = 'analytics/ODDS_WIDGET_CLICKED'

// Actions
export function openPromotionMenu(payload) {
  return Analytics.queueEvent({
    type: OPEN_PROMOTION_MENU,
    payload,
  })
}

export function claimPromotion(payload) {
  return Analytics.queueEvent({
    type: CLAIM_PROMOTION,
    payload,
  })
}

export function browsePromotion(payload) {
  return Analytics.queueEvent({
    type: BROWSE_PROMOTION,
    payload,
  })
}

function isMissing(values) {
  return values.every((value) => value == null)
}

export function oddsWidgetClicked(payload) {
  return Analytics.queueEvent({
    type: ODDS_WIDGET_CLICKED,
    payload,
  })
}

export const gtmEvents = {
  [Analytics.AUTHENTICATED]: (action, prevState, nextState) => {
    const uid = Player.getUsername(nextState.player)
    const affiliateid = Player.getAffiliateId(nextState.player) || ''

    if (isMissing([uid])) {
      return null
    }

    return {
      event: 'login',
      user: {
        uid,
        affiliateid,
      },
    }
  },

  [Analytics.DEPOSIT_AMOUNT_SELECTED]: (action, prevState, nextState) => {
    const uid = Player.getUsername(nextState.player)
    const affiliateid = Player.getAffiliateId(nextState.player) || ''
    const valueCents = action.payload

    if (isMissing([uid, valueCents])) {
      return null
    }
    return {
      event: 'amountSelected',
      deposit: {
        value: valueCents / 100,
      },
      user: {
        affiliateid,
        uid,
      },
    }
  },

  [Analytics.DEPOSIT_PAYMENT_METHOD_SELECTED]: (
    action,
    prevState,
    nextState
  ) => {
    const uid = Player.getUsername(nextState.player)
    const affiliateid = Player.getAffiliateId(nextState.player)
    const method = action.payload

    if (isMissing([uid, method])) {
      return null
    }

    return {
      event: 'paymentMethodSelected',
      deposit: {
        method,
      },
      user: {
        affiliateid,
        uid,
      },
    }
  },

  [Analytics.DEPOSIT_TRANSACTION_COMPLETED]: (action, prevState, nextState) => {
    const uid = Player.getUsername(nextState.player)
    const affiliateid = Player.getAffiliateId(nextState.player) || ''
    const depositAttempts = Player.getDepositAttempts(nextState.player)
    const valueCents = Player.getLastDepositAmount(nextState.player)
    const method = action.payload.depositMethod
    const status = action.payload.transactionStatus

    if (isMissing([uid, depositAttempts, valueCents, method, status])) {
      return null
    }

    return {
      event: 'transactionCompleted',
      deposit: {
        count: depositAttempts,
        value: valueCents / 100,
        method,
        status,
      },
      user: {
        affiliateid,
        uid,
      },
    }
  },

  [Analytics.PAGE_VIEWED]: (action) => ({
    event: 'pageview',
    page: {
      path: action.payload.path,
      title: action.payload.title,
    },
  }),

  [Analytics.REGISTRATION_STARTED]: (action, prevState, nextState) => {
    const affiliateid = Player.getAffiliateId(nextState.player)

    return {
      event: 'registrationStarted',
      user: {
        affiliateid,
      },
      registrationType: action?.payload?.registrationType,
    }
  },

  [Analytics.REGISTERED]: (action, prevState, nextState) => {
    const uid = Player.getUsername(nextState.player)
    const affiliateid = Player.getAffiliateId(nextState.player)

    if (isMissing([uid])) {
      return null
    }

    return {
      event: 'nrc',
      user: {
        uid,
        affiliateid,
      },
      registrationType: action?.payload?.registrationType,
    }
  },

  [Analytics.BONUS_CLAIMED]: (action, prevState, nextState) => {
    const currency = Player.getCurrency(nextState.player)

    if (isMissing([currency])) {
      return null
    }

    return {
      event: 'bonus',
      bonus: {
        value: action.payload.value / 100,
        currency: Player.getCurrency(nextState.player),
        claimed: action.payload.claimed ? 'yes' : 'no',
      },
    }
  },

  [OPEN_PROMOTION_MENU]: (action) => ({
    event: 'promotion-menu',
    page: {
      path: action.payload.path,
      title: action.payload.title,
    },
  }),

  [CLAIM_PROMOTION]: (action) => ({
    event: 'promotion-claim',
    promotion: {
      title: action.payload.title,
      claimed: action.payload.claimed,
      type: action.payload.type,
    },
  }),

  [BROWSE_PROMOTION]: (action) => ({
    event: 'promotion-browse',
    tab: {
      title: action.payload.title,
      destination: action.payload.destination,
    },
  }),

  [ODDS_WIDGET_CLICKED]: () => ({
    event: 'odds_widget',
  }),
}

export const mapsEvents = {
  [Analytics.DEPOSIT_TRANSACTION_COMPLETED]: (action, prevState, nextState) => {
    const btag = Player.getBtag(nextState.player)
    const valueCents = Player.getLastDepositAmount(nextState.player)
    const playerId = Player.getMapsPlayerId(nextState.player)

    const transactionStatus = action?.payload?.transactionStatus

    if (transactionStatus !== 'successful') {
      return {}
    }

    if (isMissing([valueCents, playerId])) {
      return null
    }

    return {
      actionName: 'deposit',
      btag,
      playerId,
      deposit: {
        value: valueCents / 100,
      },
    }
  },
  [Analytics.REGISTERED]: (action, prevState, nextState) => {
    const btag = Player.getBtag(nextState.player)
    const playerId = Player.getUsername(nextState.player)

    return {
      actionName: 'registration',
      btag,
      playerId,
    }
  },
}

export function analyticsRegistration() {
  return Api.fetchNotificationsByKind('analytics', {
    success: (res) => {
      const signUpNotification = res.value.find(
        (object) => object.event === 'signup'
      )

      if (signUpNotification) {
        return Api.deleteNotification(signUpNotification.id, {
          success: Analytics.register,
          version: 1,
        })
      }
    },
    version: 1,
  })
}
